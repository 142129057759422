/* @link https://utopia.fyi/space/calculator?c=375,16,1.125,1440,16,1.2,10,2,&s=0.75|0.5|0.25|0.125,1.25|1.5|2|2.5|3|4|5|6,s-l|s-xl|m-xl|2xl-4xl */

:root {
  --fluid-min-width: 375;
  --fluid-max-width: 1440;

  --fluid-screen: 100vw;
  --fluid-bp: calc(
    (var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
      (var(--fluid-max-width) - var(--fluid-min-width))
  );
}

@media screen and (min-width: 1440px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

:root {
  --f--2-min: 12;
  --f--2-max: 12;
  --step--2: calc(
    ((var(--f--2-min) / 16) * 1rem) + (var(--f--2-max) - var(--f--2-min)) *
      var(--fluid-bp)
  );

  --f--1-min: 14;
  --f--1-max: 14;
  --step--1: calc(
    ((var(--f--1-min) / 16) * 1rem) + (var(--f--1-max) - var(--f--1-min)) *
      var(--fluid-bp)
  );

  --f-0-min: 16;
  --f-0-max: 16;
  --step-0: calc(
    ((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) *
      var(--fluid-bp)
  );

  --f-1-min: 16;
  --f-1-max: 18;
  --step-1: calc(
    ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
      var(--fluid-bp)
  );

  --f-1-original-min: 18;
  --f-1-original-max: 20;
  --step-1-original: calc(
    ((var(--f-1-original-min) / 16) * 1rem) +
      (var(--f-1-original-max) - var(--f-1-original-min)) * var(--fluid-bp)
  );

  --f-2-min: 20;
  --f-2-max: 24;
  --step-2: calc(
    ((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) *
      var(--fluid-bp)
  );

  --f-2-original-min: 20;
  --f-2-original-max: 24;
  --step-2-original: calc(
    ((var(--f-2-original-min) / 16) * 1rem) +
      (var(--f-2-original-max) - var(--f-2-original-min)) * var(--fluid-bp)
  );

  --f-3-min: 22;
  --f-3-max: 28;
  --step-3: calc(
    ((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) *
      var(--fluid-bp)
  );

  --f-4-min: 24;
  --f-4-max: 32;
  --step-4: calc(
    ((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) *
      var(--fluid-bp)
  );

  --f-5-min: 28;
  --f-5-max: 40;
  --step-5: calc(
    ((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) *
      var(--fluid-bp)
  );

  --f-6-min: 32;
  --f-6-max: 48;
  --step-6: calc(
    ((var(--f-6-min) / 16) * 1rem) + (var(--f-6-max) - var(--f-6-min)) *
      var(--fluid-bp)
  );

  --f-7-min: 36;
  --f-7-max: 54;
  --step-7: calc(
    ((var(--f-7-min) / 16) * 1rem) + (var(--f-7-max) - var(--f-7-min)) *
      var(--fluid-bp)
  );

  --f-8-min: 40;
  --f-8-max: 64;
  --step-8: calc(
    ((var(--f-8-min) / 16) * 1rem) + (var(--f-8-max) - var(--f-8-min)) *
      var(--fluid-bp)
  );

  --f-9-min: 44;
  --f-9-max: 80;
  --step-9: calc(
    ((var(--f-9-min) / 16) * 1rem) + (var(--f-9-max) - var(--f-9-min)) *
      var(--fluid-bp)
  );
}

/* @link https://utopia.fyi/space/calculator?c=320,16,1.125,1400,16,1.2,10,2,&s=0.75|0.5|0.25|0.125,1.25|1.5|2|2.5|3|4|5|6,s-l|s-xl|m-xl|2xl-4xl */

:root {
  --fc-4xs: (var(--fc-s) * 0.125); /* 2 */
  --fc-3xs: (var(--fc-s) * 0.25); /* 4 */
  --fc-2xs: (var(--fc-s) * 0.5); /* 8 */
  --fc-xs: (var(--fc-s) * 0.75); /* 12 */
  --fc-s: (var(--f-0-max)); /* 16 */
  --fc-m: (var(--fc-s) * 1.25); /* 20 */
  --fc-l: (var(--fc-s) * 1.5); /* 24 */
  --fc-xl: (var(--fc-s) * 2); /* 32 */
  --fc-2xl: (var(--fc-s) * 2.5); /* 40 */
  --fc-3xl: (var(--fc-s) * 3); /* 48 */
  --fc-4xl: (var(--fc-s) * 4); /* 64 */
  --fc-5xl: (var(--fc-s) * 5); /* 80 */
  --fc-6xl: (var(--fc-s) * 6); /* 96 */

  /* T-shirt sizes */
  --space-4xs: calc(var(--fc-4xs) / 16 * 1rem);
  --space-3xs: calc(var(--fc-3xs) / 16 * 1rem);
  --space-2xs: calc(var(--fc-2xs) / 16 * 1rem);
  --space-xs: calc(var(--fc-xs) / 16 * 1rem);
  --space-s: calc(var(--fc-s) / 16 * 1rem);
  --space-m: calc(var(--fc-m) / 16 * 1rem);
  --space-l: calc(var(--fc-l) / 16 * 1rem);
  --space-xl: calc(var(--fc-xl) / 16 * 1rem);
  --space-2xl: calc(var(--fc-2xl) / 16 * 1rem);
  --space-3xl: calc(var(--fc-3xl) / 16 * 1rem);
  --space-4xl: calc(var(--fc-4xl) / 16 * 1rem);
  --space-5xl: calc(var(--fc-5xl) / 16 * 1rem);
  --space-6xl: calc(var(--fc-6xl) / 16 * 1rem);

  /* One-up pairs */
  --space-4xs-3xs: calc(
    ((var(--fc-4xs) / 16) * 1rem) + (var(--fc-3xs) - var(--fc-4xs)) *
      var(--fluid-bp)
  );
  --space-4xs-2xs: calc(
    ((var(--fc-4xs) / 16) * 1rem) + (var(--fc-2xs) - var(--fc-4xs)) *
      var(--fluid-bp)
  );
  --space-3xs-2xs: calc(
    ((var(--fc-3xs) / 16) * 1rem) + (var(--fc-2xs) - var(--fc-3xs)) *
      var(--fluid-bp)
  );
  --space-2xs-xs: calc(
    ((var(--fc-2xs) / 16) * 1rem) + (var(--fc-xs) - var(--fc-2xs)) *
      var(--fluid-bp)
  );
  --space-2xs-s: calc(
    ((var(--fc-2xs) / 16) * 1rem) + (var(--fc-s) - var(--fc-2xs)) *
      var(--fluid-bp)
  );
  --space-xs-s: calc(
    ((var(--fc-xs) / 16) * 1rem) + (var(--fc-s) - var(--fc-xs)) *
      var(--fluid-bp)
  );
  --space-s-m: calc(
    ((var(--fc-s) / 16) * 1rem) + (var(--fc-m) - var(--fc-s)) * var(--fluid-bp)
  );
  --space-m-l: calc(
    ((var(--fc-m) / 16) * 1rem) + (var(--fc-l) - var(--fc-m)) * var(--fluid-bp)
  );
  --space-l-xl: calc(
    ((var(--fc-l) / 16) * 1rem) + (var(--fc-xl) - var(--fc-l)) * var(--fluid-bp)
  );
  --space-xl-2xl: calc(
    ((var(--fc-xl) / 16) * 1rem) + (var(--fc-2xl) - var(--fc-xl)) *
      var(--fluid-bp)
  );
  --space-2xl-3xl: calc(
    ((var(--fc-2xl) / 16) * 1rem) + (var(--fc-3xl) - var(--fc-2xl)) *
      var(--fluid-bp)
  );
  --space-3xl-4xl: calc(
    ((var(--fc-3xl) / 16) * 1rem) + (var(--fc-4xl) - var(--fc-3xl)) *
      var(--fluid-bp)
  );
  --space-4xl-5xl: calc(
    ((var(--fc-4xl) / 16) * 1rem) + (var(--fc-5xl) - var(--fc-4xl)) *
      var(--fluid-bp)
  );
  --space-5xl-6xl: calc(
    ((var(--fc-5xl) / 16) * 1rem) + (var(--fc-6xl) - var(--fc-5xl)) *
      var(--fluid-bp)
  );

  /* Custom pairs */
  --space-2xs-m: calc(
    ((var(--fc-2xs) / 16) * 1rem) + (var(--fc-m) - var(--fc-2xs)) *
      var(--fluid-bp)
  );
  --space-xs-2xs: calc(
    ((var(--fc-xs) / 16) * 1rem) + (var(--fc-2xs) - var(--fc-xs)) *
      var(--fluid-bp)
  );
  --space-xs-l: calc(
    ((var(--fc-xs) / 16) * 1rem) + (var(--fc-l) - var(--fc-xs)) *
      var(--fluid-bp)
  );
  --space-xs-m: calc(
    ((var(--fc-xs) / 16) * 1rem) + (var(--fc-m) - var(--fc-xs)) *
      var(--fluid-bp)
  );
  --space-s-l: calc(
    ((var(--fc-s) / 16) * 1rem) + (var(--fc-l) - var(--fc-s)) * var(--fluid-bp)
  );
  --space-s-xl: calc(
    ((var(--fc-s) / 16) * 1rem) + (var(--fc-xl) - var(--fc-s)) * var(--fluid-bp)
  );
  --space-s-2xl: calc(
    ((var(--fc-s) / 16) * 1rem) + (var(--fc-2xl) - var(--fc-s)) *
      var(--fluid-bp)
  );
  --space-s-3xl: calc(
    ((var(--fc-s) / 16) * 1rem) + (var(--fc-3xl) - var(--fc-s)) *
      var(--fluid-bp)
  );
  --space-s-4xl: calc(
    ((var(--fc-s) / 16) * 1rem) + (var(--fc-4xl) - var(--fc-s)) *
      var(--fluid-bp)
  );
  --space-m-xl: calc(
    ((var(--fc-m) / 16) * 1rem) + (var(--fc-xl) - var(--fc-m)) * var(--fluid-bp)
  );
  --space-m-2xl: calc(
    ((var(--fc-m) / 16) * 1rem) + (var(--fc-2xl) - var(--fc-m)) *
      var(--fluid-bp)
  );
  --space-l-2xl: calc(
    ((var(--fc-l) / 16) * 1rem) + (var(--fc-2xl) - var(--fc-l)) *
      var(--fluid-bp)
  );
  --space-3xs-3xl: calc(
    ((var(--fc-3xs) / 16) * 1rem) + (var(--fc-3xl) - var(--fc-3xs)) *
      var(--fluid-bp)
  );
  --space-l-3xl: calc(
    ((var(--fc-l) / 16) * 1rem) + (var(--fc-3xl) - var(--fc-l)) *
      var(--fluid-bp)
  );
  --space-xl-3xl: calc(
    ((var(--fc-xl) / 16) * 1rem) + (var(--fc-3xl) - var(--fc-xl)) *
      var(--fluid-bp)
  );
  --space-xl-4xl: calc(
    ((var(--fc-xl) / 16) * 1rem) + (var(--fc-4xl) - var(--fc-xl)) *
      var(--fluid-bp)
  );
  --space-2xl-4xl: calc(
    ((var(--fc-2xl) / 16) * 1rem) + (var(--fc-4xl) - var(--fc-2xl)) *
      var(--fluid-bp)
  );
  --space-2xl-5xl: calc(
    ((var(--fc-2xl) / 16) * 1rem) + (var(--fc-5xl) - var(--fc-2xl)) *
      var(--fluid-bp)
  );
  --space-xl-6xl: calc(
    ((var(--fc-xl) / 16) * 1rem) + (var(--fc-6xl) - var(--fc-xl)) *
      var(--fluid-bp)
  );
  --space-2xl-6xl: calc(
    ((var(--fc-2xl) / 16) * 1rem) + (var(--fc-6xl) - var(--fc-2xl)) *
      var(--fluid-bp)
  );
  --space-3xl-6xl: calc(
    ((var(--fc-3xl) / 16) * 1rem) + (var(--fc-6xl) - var(--fc-3xl)) *
      var(--fluid-bp)
  );
  --space-4xl-6xl: calc(
    ((var(--fc-4xl) / 16) * 1rem) + (var(--fc-6xl) - var(--fc-4xl)) *
      var(--fluid-bp)
  );

  /* Globals */
  --site-gutter: var(--space-l-2xl);
  --negative-site-gutter: calc(-1 * var(--site-gutter));
}
